import appScreen1 from "../../assets/img/app-screen-1.png"
import workingMan1 from "../../assets/img/working-man-1.jpg"
import workingMan2 from "../../assets/img/working-man-2.jpg"

const Functionnalities = () => {
    return (
        <div className="py-32">
            <div className="container">
                <p className="text-2xl text-orange-500 lg:text-center">Fonctionnalités de l'application</p>
                <h2 className="text-4xl lg:text-7xl lg:text-center leading-snug">Despii fait 100% du travail,<br/>vous récoltez <span className="bg-gradient-to-r from-[#DC6617]
           to-[#47134B] text-transparent bg-clip-text">80% des bénéfices</span></h2>

                <div className="w-fit max-w-[1125px] mx-auto">
                    <div className="flex flex-col-reverse lg:flex-row justify-between mt-24">
                        <div className="flex flex-col items-start justify-center lg:max-w-[435px]">
                            <h3 className="text-3xl lg:text-5xl mb-7">Gestion locative complète</h3>
                            <p className="text-xl mb-12">De la maintenance au repositionnement en passant par la
                                logistique,
                                Despii s’occupe de tout. Votre flotte est toujours opérationnelle et prête à satisfaire
                                vos
                                clients, sans aucun effort de votre part.</p>
                            <a href="https://calendly.com/contact-despii" className="rounded-3xl border-2 border-white mx-auto lg:mx-0 py-6 px-8">Obtenir une estimation
                            </a>
                        </div>
                        <img className="max-w-[535px] w-full aspect-square shrink-0 lg:mr-9 my-6 lg:my-0 bg-black rounded-3xl" src={appScreen1} alt=""/>
                    </div>

                    <div className="flex flex-col lg:flex-row justify-between mt-24">
                        <img className="max-w-[535px] w-full aspect-square shrink-0 lg:mr-9 my-6 lg:my-0 bg-black rounded-3xl" src={workingMan1} alt=""/>
                        <div className="flex flex-col items-start justify-center lg:max-w-[435px]">
                            <h3 className="text-3xl lg:text-5xl mb-7">Service client intégré</h3>
                            <p className="text-xl mb-12">Nous prenons en charge chaque interaction avec vos clients :
                                assistance multilingue, gestion des paiements, et résolution de problèmes. Avec Despii,
                                vous garantissez un service irréprochable.</p>
                            <a href="https://calendly.com/contact-despii" className="rounded-3xl border-2 border-white mx-auto lg:mx-0 py-6 px-8">Obtenir une estimation
                            </a>
                        </div>
                    </div>

                    <div className="flex flex-col-reverse lg:flex-row justify-between mt-24">
                        <div className="flex flex-col items-start justify-center lg:max-w-[435px]">
                            <h3 className="text-3xl lg:text-5xl mb-7">Maintenance et assurance incluses</h3>
                            <p className="text-xl mb-12">Despii prend en charge les réparations et l’entretien régulier
                                de vos trottinettes. Grâce à nos partenaires comme Axa, vous bénéficiez d’une couverture
                                complète contre les vols, dommages ou incidents, pour une tranquillité d’esprit
                                totale.</p>
                            <a href="https://calendly.com/contact-despii" className="rounded-3xl border-2 border-white mx-auto lg:mx-0 py-6 px-8">Obtenir une estimation
                            </a>
                        </div>
                        <img className="max-w-[535px] w-full aspect-square shrink-0 lg:mr-9 my-6 lg:my-0 bg-black rounded-3xl" src={workingMan2} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Functionnalities;