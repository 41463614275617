import TwoPhonesImg from "../../assets/img/phonesScreens.png"
import appImg from "../../assets/img/appImg.png"
import ellipseBg from "../../assets/img/ellipse-bg.png";

const CalculateGain = () => {
    return (
        <div className="bg-orange-500 rounded-3xl mx-5 pt-16 bg-no-repeat bg-right-bottom" style={{backgroundImage: `url(${ellipseBg})`}}>
            <div className="container w-fit flex flex-col-reverse lg:flex-row justify-center items-end">
                <img className="max-h-[500px] lg:mr-36" src={TwoPhonesImg} alt="2 phones"/>
                <div className="max-w-[500px] pb-16">
                    <h3 className="text-4xl lg:text-7xl mb-7">Calculez vos gains potentiels dès aujourd’hui</h3>
                    <p className="text-xl mb-12">Découvrez combien vous pouvez gagner en proposant une flotte de
                        trottinettes partagées. Une estimation rapide et sans engagement.</p>
                    <a href="https://calendly.com/contact-despii" className="rounded-3xl p-3 bg-black flex justify-center items-center w-full lg:w-auto"><img className="size-11 mr-6" src={appImg} alt="icon app"/> Obtenir une estimation</a>
                </div>
            </div>
        </div>
    )
}

export default CalculateGain;