import React from "react";
import appScreen2 from "../../assets/img/app-screen-2.png"
import appScreen3 from "../../assets/img/app-screen-3.png"
import appScreen4 from "../../assets/img/app-screen-4.png"
import {FaArrowUp} from "react-icons/fa";
import {FaArrowRight} from "react-icons/fa6";


const HowItWorks = () => {
    return (
        <div className="py-32">
            <div className="container">
                <p className="text-2xl text-orange-500 text-center">Comment ça marche?</p>
                <h2 className="text-4xl lg:text-7xl text-center leading-snug"><span className="bg-gradient-to-r from-[#DC6617]
           to-[#47134B] text-transparent bg-clip-text">Une expérience fluide</span><br/>pour vos utilisateurs</h2>


                <div className="relative bg-[#161616] rounded-3xl flex flex-col-reverse xl:flex-row justify-between p-6 mb-12 mt-16">
                    <div className="hidden xl:block absolute left-0 top-1/2 -translate-y-1/2">
                        <div className="w-[8px] h-[100px] rounded-r-3xl bg-orange-500"></div>
                        <div className="w-[8px] h-[100px] rounded-r-3xl bg-gray-700 my-1"></div>
                        <div className="w-[8px] h-[100px] rounded-r-3xl bg-gray-700"></div>
                    </div>

                    <div className="max-w-[450px] flex flex-col justify-between items-start mx-auto xl:ml-16 mr-auto">
                        <button className="size-16 border-2 border-white rounded-3xl hidden xl:flex justify-center items-center"><FaArrowRight className="text-xl text-white" /></button>
                        <h3 className="text-3xl xl:text-5xl my-7">Déverrouillage simple via l’application Despii</h3>
                        <p className="text-xl mb-12">Avec l’application Despii, vos clients accèdent rapidement à une
                            trottinette en scannant un QR code. En quelques secondes, ils peuvent commencer leur trajet
                            sans effort.</p>
                        <p>01/03</p>
                    </div>
                    <img className="max-w-[535px] w-full aspect-square shrink-0 xl:mr-9 mx-auto rounded-3xl" src={appScreen2} alt=""/>
                </div>

                <div className="relative bg-[#161616] rounded-3xl flex flex-col-reverse xl:flex-row justify-between p-6 mb-12">
                    <div className="hidden xl:block absolute left-0 top-1/2 -translate-y-1/2">
                        <div className="w-[8px] h-[100px] rounded-r-3xl bg-orange-500"></div>
                        <div className="w-[8px] h-[100px] rounded-r-3xl bg-orange-500 my-1"></div>
                        <div className="w-[8px] h-[100px] rounded-r-3xl bg-gray-700"></div>
                    </div>

                    <div className="max-w-[450px] flex flex-col justify-between items-start mx-auto xl:ml-16 mr-auto">
                        <button className="size-16 border-2 border-white rounded-3xl hidden xl:flex justify-center items-center"><FaArrowRight className="text-xl text-white" /></button>
                        <h3 className="text-3xl xl:text-5xl my-7">Une solution flexible pour tous leurs trajets</h3>
                        <p className="text-xl mb-12">Que ce soit pour une course rapide ou une promenade plus longue, vos clients roulent selon leurs besoins. Une liberté totale pour explorer, se déplacer ou simplement profiter de la ville.</p>
                        <p>02/03</p>
                    </div>
                    <img className="max-w-[535px] w-full aspect-square shrink-0 xl:mr-9 mx-auto rounded-3xl" src={appScreen3} alt=""/>
                </div>

                <div className="relative bg-[#161616] rounded-3xl flex flex-col-reverse xl:flex-row justify-between p-6 mb-12">
                    <div className="hidden xl:block absolute left-0 top-1/2 -translate-y-1/2">
                        <div className="w-[8px] h-[100px] rounded-r-3xl bg-orange-500"></div>
                        <div className="w-[8px] h-[100px] rounded-r-3xl bg-orange-500 my-1"></div>
                        <div className="w-[8px] h-[100px] rounded-r-3xl bg-orange-500"></div>
                    </div>

                    <div className="max-w-[450px] flex flex-col justify-between items-start mx-auto xl:ml-16 mr-auto">
                        <button className="size-16 border-2 border-white rounded-3xl hidden xl:flex justify-center items-center"><FaArrowRight className="text-xl text-white" /></button>
                        <h3 className="text-3xl xl:text-5xl my-7">Ils laissent la trottinette, Despii la collecte pour vous</h3>
                        <p className="text-xl mb-12">À la fin de leur trajet, vos clients déposent simplement la trottinette dans un emplacement dédié. Ensuite, Despii se charge de la collecter, de la recharger et de la repositionner, sans aucune intervention de votre part.</p>
                        <p>03/03</p>
                    </div>
                    <img className="max-w-[535px] w-full aspect-square shrink-0 xl:mr-9 mx-auto rounded-3xl" src={appScreen4} alt=""/>
                </div>
            </div>
        </div>
    )
}

export default HowItWorks;