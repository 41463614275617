import React, {useState} from "react";
import scoot5img from "../../assets/img/scoot5.png";
import {FaChevronDown} from "react-icons/fa";


const QAndA = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const faqs = [
        {
            question: '1. Despii possède-t-il les trottinettes proposées sur la plateforme ?',
            answer:
                'Non, Despii ne possède aucune trottinette. Nous sommes une plateforme qui met en relation les propriétaires et les locataires. Les propriétaires fixent librement leurs tarifs, qu\'ils soient à la minute, à l\'heure ou à la journée.',
        },
        { question: '2. Quelles sont les exigences pour qu’une trottinette soit acceptée sur Despii ?', answer: `Pour garantir la sécurité et la conformité légale, les trottinettes doivent :<br/>
                <br/>
                Être équipées d\'une plaque d’immatriculation.<br/>
                Avoir une assurance responsabilité civile valide.<br/>
                Disposer d’un rétroviseur, de clignotants fonctionnels, d’un cadenas intelligent, et d’un boîtier IoT.<br/>
                Avoir moins de 2 ans d’âge.<br/>
                Être de n'importe quelle marque, tant qu'elles respectent ces normes.` },
        { question: '3. Où dois-je garer une trottinette Despii après utilisation ?', answer: 'Les trottinettes doivent impérativement être garées sur des emplacements réservés aux deux-roues. En cas de non-respect, une amende peut être infligée au locataire.' },
        { question: '4. Quels sont les critères pour devenir utilisateur Despii ?', answer: `Pour garantir la sécurité de la communauté, les utilisateurs doivent :<br/>
                <br/>
                Valider leur compte en fournissant une pièce d’identité officielle.<br/>
                Avoir au moins 16 ans.` },
        {question: '5. Qui décide de la zone géographique où une trottinette peut être louée ?', answer: 'C’est le propriétaire qui définit la zone de location. Cela lui permet d’adapter l’utilisation de sa trottinette en fonction des besoins locaux.'},
        {question: '6. Que se passe-t-il en cas d\'accident pendant une location ?', answer: 'Despii propose des solutions d’assurance via ses partenaires. Ces assurances protègent aussi bien le locataire que le propriétaire contre les conséquences financières imprévues.'}
    ];

    return (
        <div className="py-32">
            <div className="container">
                <p className="text-2xl text-orange-500 text-center">FAQ</p>
                <h2 className="text-4xl lg:text-7xl text-center leading-snug mb-24">Questions <span className="bg-gradient-to-r from-[#DC6617]
           to-[#47134B] text-transparent bg-clip-text">fréquentes</span></h2>

                <div className="lg:flex items-start">
                    <img className="max-w-[535px] w-full aspect-square shrink-0 mr-9 bg-black rounded-3xl" src={scoot5img}
                         alt="scooter"/>

                    <div className="w-full mt-6 lg:mt-0">
                        {faqs.map((faq, index) => (
                            <div key={index} className={`border-b border-white/25 ${index === 0 ? 'border-t' : ''}`}>
                                <button
                                    onClick={() => toggleAccordion(index)}
                                    className="w-full text-left py-6 flex justify-between items-start focus:outline-none"
                                >
                                <span className={`text-xl lg:text-xl text-orange-500`}>
                                  {faq.question}
                                </span>
                                <FaChevronDown className={`text-2xl text-orange-500 transition-transform shrink-0 ${openIndex === index ? 'rotate-180' : ''}`} />
                                </button>

                                {openIndex === index && (
                                    <div className="lg:text-xl pb-6" dangerouslySetInnerHTML={{ __html: faq.answer }} />
                                )}
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default QAndA;