import React from "react";
import euroIcon from "../../assets/img/euro-icon.png"
import thumbIcon from "../../assets/img/thumb-icon.png"
import smartphoneIcon from "../../assets/img/smartphone-icon.png"
import phoneIcon from "../../assets/img/phone-icon.png"

const Advantages = () => {
    return (
        <div className="py-32">
            <div className="container">
                <p className="text-2xl text-orange-500 text-center">Avantages</p>
                <h2 className="text-4xl lg:text-7xl text-center leading-snug">Profitez d’une solution rentable,<br/><span className="bg-gradient-to-r from-[#DC6617]
           to-[#47134B] text-transparent bg-clip-text">fiable et parfaitement gérée</span></h2>

                <div className="grid grid-cols-2 gap-5 lg:flex justify-between mt-24">
                    <div className="lg:max-w-[210px] bg-gradient-to-r from-orange-500 to-purple-950 rounded-full px-4 py-20">
                        <img className="h-20 mx-auto" src={euroIcon} alt="icon"/>
                        <p className="text-xl text-center mt-4">Jusqu’à 12 000 €/an par trottinette.</p>
                    </div>

                    <div className="lg:max-w-[210px] bg-gradient-to-r from-orange-500 to-purple-950 rounded-full px-4 py-20">
                        <img className="h-20 mx-auto" src={thumbIcon} alt="icon"/>
                        <p className="text-xl text-center mt-4">Seuil de rentabilité environ en 2 mois</p>
                    </div>

                    <div className="lg:max-w-[210px] bg-gradient-to-r from-orange-500 to-purple-950 rounded-full px-4 py-20">
                        <img className="h-20 mx-auto" src={smartphoneIcon} alt="icon"/>
                        <p className="text-xl text-center mt-4">Application intuitif pour une gestion sans effort</p>
                    </div>

                    <div className="lg:max-w-[210px] bg-gradient-to-r from-orange-500 to-purple-950 rounded-full px-4 py-20">
                        <img className="h-20 mx-auto" src={phoneIcon} alt="icon"/>
                        <p className="text-xl text-center mt-4">Assistance multilingue 24/7 pour vos utilisateurs</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Advantages;