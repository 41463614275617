import React from "react";

import hotelImg from "../../assets/img/hotel.jpeg";
import barImg from "../../assets/img/bar.jpeg";
import bedmakeImg from "../../assets/img/bedmake.jpeg";
import bedroomImg from "../../assets/img/bedroom.jpeg";
import studentImg from "../../assets/img/student.jpeg";
import parkingImg from "../../assets/img/parking.jpeg";
import handShakeImg from "../../assets/img/hand-shake.jpeg";

const PerfectFor = (props) => {

    const blocks = [
        {
            title: 'Particuliers à particuliers',
            img: handShakeImg,
        },
        {
            title: 'Hôtels',
            img: hotelImg,
        },
        {
            title: 'Auberges de jeunesse',
            img: bedroomImg,
        },
        {
            title: 'Conciergeries Airbnb',
            img: bedmakeImg,
        },
        {
            title: 'Résidences étudiantes',
            img: studentImg,
        },
        {
            title: 'Commerces de proximité',
            img: barImg,
        },
        {
            title: 'Parking privés',
            img: parkingImg,
        },
    ];

    return (
        <div className="py-32">
                <div className="container">
                    <p className="text-2xl text-orange-500 text-center">Secteurs d’activités</p>
                    <h2 className="text-4xl lg:text-7xl text-center leading-snug">Le service parfait pour<br/><span className="bg-gradient-to-r from-[#DC6617]
           to-[#47134B] text-transparent bg-clip-text">développer votre activité</span></h2>
                </div>

                <div className="flex overflow-scroll no-scrollbar container my-24 mr-0 pr-0">
                    {blocks.map((block, index) => (
                        <div
                            className="relative shrink-0 w-[298px] h-[565px] rounded-3xl overflow-hidden bg-blue-900 mr-24 bg-cover bg-center" style={{backgroundImage: `url(${block.img})`}}>
                            <p className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-purple-950 to-transparent text-4xl text-center font-bold px-4 py-20">{ block.title }</p>
                        </div>
                    ))}
                </div>

            <a href="https://calendly.com/contact-despii" className="block rounded-3xl p-5 mx-auto bg-gradient-to-r from-orange-500 to-purple-950">Obtenir
                une estimation
            </a>
        </div>
    )
}

export default PerfectFor;