import React from "react";

const PolitiqueConfidentialite = () => {
  const paragraphStyle = { marginBottom: "15px", textAlign: "left", paddingLeft: "5px" };
  const headingStyle = { marginBottom: "10px", textAlign: "left", paddingLeft: "5px" };
  const listStyle = { marginBottom: "15px", textAlign: "left", paddingLeft: "5px", listStyleType: "disc", marginLeft: "20px" };

  return (
    <div style={{ backgroundColor: "#262626", minHeight: "100vh", color: "white", padding: "20px 5px" }}>
      <div style={{ maxWidth: "800px", margin: "0 auto" }}>
        <h1 style={headingStyle}>Politique de Confidentialité de Despii</h1>
        <p style={paragraphStyle}>Dernière mise à jour : 29 juillet 2024</p>
        <hr style={{ borderColor: "white", margin: "10px 0" }} />
        
        <div>
          <p style={paragraphStyle}>Chez Despii, nous accordons une grande importance à la protection de vos données personnelles et à votre vie privée. Cette politique de confidentialité explique quelles informations nous collectons, comment nous les utilisons, et les choix dont vous disposez concernant vos données.</p>

          <h2 style={headingStyle}>1. Données personnelles que nous collectons</h2>
          <p style={paragraphStyle}>Nous collectons des données personnelles pour vous fournir nos services de manière optimale. Les informations que nous collectons incluent :</p>
          <ul style={listStyle}>
            <li><strong>Données que vous fournissez directement :</strong> Lors de la création de votre compte, nous pouvons collecter votre nom, adresse e-mail, numéro de téléphone, et toute autre information nécessaire pour utiliser nos services.</li>
            <li><strong>Données liées à l'utilisation du service :</strong> Nous collectons des informations sur votre activité sur la plateforme, comme les pages consultées, les interactions avec d'autres utilisateurs, ou les informations sur les transactions effectuées.</li>
            <li><strong>Données techniques :</strong> Cela inclut votre adresse IP, votre type de navigateur, vos paramètres de langue, et des informations sur l'appareil que vous utilisez.</li>
          </ul>

          <h2 style={headingStyle}>2. Comment nous utilisons vos données</h2>
          <p style={paragraphStyle}>Les données que nous collectons sont utilisées dans les buts suivants :</p>
          <ul style={listStyle}>
            <li>Pour fournir et améliorer nos services ;</li>
            <li>Pour vous permettre de vous connecter, interagir et effectuer des transactions sur la plateforme ;</li>
            <li>Pour vous envoyer des notifications ou des communications marketing, si vous y avez consenti ;</li>
            <li>Pour détecter, prévenir et répondre aux fraudes ou autres activités illicites ;</li>
            <li>Pour respecter nos obligations légales et réglementaires.</li>
          </ul>

          <h2 style={headingStyle}>3. Partage de vos données</h2>
          <p style={paragraphStyle}>Nous nous engageons à ne pas vendre vos données personnelles à des tiers. Cependant, vos données peuvent être partagées avec :</p>
          <ul style={listStyle}>
            <li>Nos partenaires ou prestataires de services qui nous aident à exploiter le site (hébergement, outils d'analyse, gestion des paiements) ;</li>
            <li>Les autorités compétentes, si cela est requis par la loi ou pour protéger nos droits légaux.</li>
          </ul>

          <h2 style={headingStyle}>4. Stockage et sécurité de vos données</h2>
          <p style={paragraphStyle}>Nous utilisons des mesures techniques et organisationnelles pour protéger vos données contre tout accès, modification ou divulgation non autorisés. Vos données sont stockées dans des centres de données sécurisés et ne sont conservées que le temps nécessaire pour fournir nos services ou respecter nos obligations légales.</p>

          <h2 style={headingStyle}>5. Vos droits</h2>
          <p style={paragraphStyle}>En vertu des réglementations applicables, vous disposez des droits suivants concernant vos données personnelles :</p>
          <ul style={listStyle}>
            <li>Accéder à vos données et obtenir une copie ;</li>
            <li>Demander la rectification de vos données si elles sont inexactes ;</li>
            <li>Demander la suppression de vos données dans certaines circonstances ;</li>
            <li>Vous opposer au traitement de vos données ou en demander la limitation ;</li>
            <li>Retirer votre consentement à tout moment pour les traitements basés sur celui-ci ;</li>
            <li>Porter plainte auprès d'une autorité de protection des données, comme la CNIL en France.</li>
          </ul>

          <h2 style={headingStyle}>6. Cookies et technologies similaires</h2>
          <p style={paragraphStyle}>Nous utilisons des cookies pour améliorer votre expérience sur notre site. Les cookies nous permettent de vous reconnaître, de personnaliser votre navigation et d'analyser l'utilisation de nos services. Vous pouvez gérer vos préférences en matière de cookies via les paramètres de votre navigateur.</p>

          <h2 style={headingStyle}>7. Modifications de cette politique</h2>
          <p style={paragraphStyle}>Nous pouvons être amenés à mettre à jour cette politique pour refléter les évolutions de nos pratiques ou pour nous conformer aux obligations légales. Toute modification sera publiée sur cette page avec la date de mise à jour.</p>

          <h2 style={headingStyle}>8. Contactez-nous</h2>
          <p style={paragraphStyle}>Si vous avez des questions ou des préoccupations concernant cette politique de confidentialité, vous pouvez nous écrire à l'adresse suivante : contact@despii.com</p>

          <hr style={{ borderColor: "white", margin: "10px 0" }} />
          <p style={paragraphStyle}>En utilisant notre plateforme, vous acceptez les termes de cette politique de confidentialité. Nous vous remercions de votre confiance.</p>
        </div>
      </div>
    </div>
  );
};

export default PolitiqueConfidentialite;
