import scoot3img from "../assets/img/scoot3.jpeg";
import appImg from "../assets/img/appImg.png";
import ellipseBg from "../assets/img/ellipse-bg.png";

const OurMission = props => {
    return (
        <div className="bg-orange-500 py-10 lg:py-32 bg-no-repeat bg-right-bottom" style={{backgroundImage: `url(${ellipseBg})`}}>
            <div className="container flex flex-col-reverse lg:flex-row">
                <img className="max-w-[535px] w-full aspect-square shrink-0 mx-auto lg:mx-0 lg:mr-9 bg-black rounded-3xl" src={scoot3img} alt=""/>
                <div className="flex flex-col items-start lg:justify-between">
                    <p className="rounded-xl text-xs lg:text-sm border-2 border-white uppercase p-2 lg:px-4 lg:py-3">Notre mission</p>
                    <h2 className="text-4xl lg:text-7xl my-5 lg:my-0">Transformez les déplacements en source de revenus</h2>
                    <p>Proposez un service recherché et exclusif. Laissez Despii gérer la logistique, la maintenance et
                        le service client. Soyez rémunéré à chaque trajet.</p>
                    <button className="rounded-3xl p-3 bg-black w-full lg:w-auto flex justify-center items-center my-5 lg:my-0"><img className="size-11 mr-6" src={appImg} alt="icon app"/> Obtenir une estimation
                    </button>
                </div>
            </div>
        </div>
    )
}

export default OurMission;